





















import { Component, Vue } from 'vue-property-decorator';
import ArchiveCatalog from './components/archive-catalog.vue';
import ArchiveContent from './components/archive-content.vue';

@Component({
  name: 'PersonalArchive',
  components: { ArchiveCatalog, ArchiveContent }
})
export default class PersonalArchive extends Vue {
  // 选中的目录
  selectedCatalog: any = {};

  /**
   * 选中左侧某个目录时，切换右侧内容
   */
  changeCatalog(data) {
    this.selectedCatalog = data;
  }

  /**
   * 右侧内容区发生改变时，重新加载目录
   */
  handleReloadCatalog() {
    (this.$refs.catalogRef as any).getTreeData();
  }
  /**
   * 左侧目录区发生改变时，重新加载右侧内容
   */
  handleReloadContent() {
    (this.$refs.contentRef as any).initData();
  }
}
