






































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ArchiveCatalogForm from './archive-catalog-form.vue';
import ArchiveApi from '@/api/archive/ArchiveApi';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';

@Component({
  name: 'ArchiveCatalog',
  components: { ArchiveCatalogForm }
})
export default class ArchiveCatalog extends Vue {
  @Prop()
  type!: string; // 目录类型: personal:个人， default:默认
  currentTab: string = 'personal';
  // 个人归档树列表
  personalArchiveData: any[] = [];
  // 默认归档树列表
  defaultArchiveData: any[] = [];
  // 目录树过滤文本框内容
  searchText: string = '';
  //选中的树节点
  selectNode: any = null;
  // 是否显示目录类型弹窗
  dialogVisible: boolean = false;

  /**
   * created
   */
  created() {
    // 获取目录类型列表
    this.getTreeData();
  }

  /**
   * 获取归档目录
   */
  getTreeData() {
    let params: any = {};
    if (this.searchText) {
      params.name = this.searchText;
    }
    ArchiveApi.getCatalogTree(params).then((res: any) => {
      if (res.code == '1') {
        this.personalArchiveData = res.data.filter(item => {
          return item.owner != '-1';
        });
        this.defaultArchiveData = res.data.filter(item => {
          return item.owner == '-1';
        });
      } else {
        this.personalArchiveData = [];
        this.defaultArchiveData = [];
        this.$message.error(res.message);
      }
    });
  }

  handleBack() {
    this.$router.go(-1);
  }

  /**
   * 新增app类型
   */
  handleCreate() {
    this.selectNode = null;
    this.dialogVisible = true;
  }

  /**
   * 编辑app
   * @param app
   */
  handleEdit(app) {
    this.selectNode = SystemUtil.cloneDeep(app);
    this.dialogVisible = true;
  }

  /**
   * 关闭弹窗
   */
  closeDialog(reload) {
    this.dialogVisible = false;
    if (reload) {
      this.getTreeData();
      this.$emit('reload');
    }
  }

  /**
   * 删除目录类型
   */
  handleRemove(row) {
    this.$confirm(this.$t('lang_whether_or_not_to_delete') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    })
      .then(() => {
        let params = [row.id];
        ArchiveApi.removeCatalogs(params)
          .then((res: Record<any, any>) => {
            if (res.code == '1') {
              this.getTreeData();
              this.$emit('reload');
            } else {
              this.$message.error(res.message);
            }
          })
          .finally(() => {});
      })
      .catch(() => {});
  }

  /**
   * 目录树节点点击事件
   * @param data
   */
  handleNodeClick(data) {
    this.selectNode = data;
    this.handleEmit();
  }

  handleEmit() {
    this.$emit('change', this.selectNode);
  }
}
