import { render, staticRenderFns } from "./archive-content.vue?vue&type=template&id=2b221d08&scoped=true&"
import script from "./archive-content.vue?vue&type=script&lang=ts&"
export * from "./archive-content.vue?vue&type=script&lang=ts&"
import style0 from "./archive-content.vue?vue&type=style&index=0&id=2b221d08&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b221d08",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\123\\river-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b221d08')) {
      api.createRecord('2b221d08', component.options)
    } else {
      api.reload('2b221d08', component.options)
    }
    module.hot.accept("./archive-content.vue?vue&type=template&id=2b221d08&scoped=true&", function () {
      api.rerender('2b221d08', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/archive/components/archive-content.vue"
export default component.exports