var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hg-100-percent" },
    [
      _c(
        "glb-spliter",
        {
          attrs: {
            gutter: 10,
            spanList: [
              { span: 5, slot: "left" },
              { span: 19, slot: "right" },
            ],
          },
        },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("archive-catalog", {
                ref: "catalogRef",
                attrs: { type: "personal" },
                on: {
                  change: _vm.changeCatalog,
                  reload: _vm.handleReloadContent,
                },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "right" },
            [
              _c("archive-content", {
                ref: "contentRef",
                attrs: { catalog: _vm.selectedCatalog },
                on: { reload: _vm.handleReloadCatalog },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }