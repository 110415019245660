var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title: _vm.$t(_vm.dialogTitle),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _vm.dialogVisible
        ? _c(
            "el-form",
            {
              ref: "formRef",
              attrs: {
                model: _vm.formData,
                rules: _vm.formRules,
                "label-suffix": ": ",
                "label-width": "170px",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "name",
                                label: _vm.$t("lang_catalog_name"),
                                prop: "name",
                              },
                            },
                            [
                              _c("lang-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.formData.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "name", $$v)
                                  },
                                  expression: "formData.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "parentId",
                                label: _vm.$t("lang_parent_application_type"),
                                prop: "pid",
                              },
                            },
                            [
                              _c("treeselect", {
                                attrs: {
                                  options: _vm.treeData,
                                  "append-to-body": "",
                                  "z-index": 2999,
                                  "default-expand-level": 1,
                                  normalizer: _vm.treeProps,
                                  placeholder: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "option-label",
                                      fn: function (ref) {
                                        var node = ref.node
                                        return [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t(node.label))
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "value-label",
                                      fn: function (ref) {
                                        var node = ref.node
                                        return [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t(node.label))
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1573169889
                                ),
                                model: {
                                  value: _vm.formData.pid,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "pid", $$v)
                                  },
                                  expression: "formData.pid",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "sort",
                                label: _vm.$t("lang_sort"),
                                prop: "sort",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: { precision: 2, step: 1, min: 0 },
                                model: {
                                  value: _vm.formData.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "sort", $$v)
                                  },
                                  expression: "formData.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.currentTab == "default"
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "lang_create_document_permission_under_directory"
                                    ),
                                  },
                                },
                                [
                                  _c("div", { staticStyle: { color: "red" } }, [
                                    _vm._v(
                                      _vm._s(
                                        "(" +
                                          _vm.$t(
                                            "lang_if_not_selected_all_users_can_create"
                                          ) +
                                          ")"
                                      )
                                    ),
                                  ]),
                                  _vm.visible
                                    ? _c("bind-scope", {
                                        attrs: {
                                          "select-scope-list":
                                            _vm.selectedScopeList,
                                        },
                                        on: { done: _vm.updateSelectDeptUser },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveFormData } },
            [_vm._v(_vm._s(_vm.$t("lang_save_")))]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }