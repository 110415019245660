




































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import BindScope from '@/components/bind-scope/index.vue';
import ArchiveApi from '@/api/archive/ArchiveApi';
import { RoleSelectDeptUserModel } from '@/models/system/RoleModel';

@Component({
  name: 'ArchiveCatalogForm',
  components: { BindScope }
})
export default class ArchiveCatalogForm extends Vue {
  // ref
  @Ref() readonly formRef;

  // 弹窗显隐
  @Prop({ default: false }) visible!: boolean;
  // tab类型  personal： 个人；default： 默认
  @Prop({ default: '' }) currentTab!: string;

  // 目录对象
  @Prop() catalog: any;

  // 目录对象-表单
  formData: any = {
    name: '',
    sort: null,
    pid: null
  };

  dialogTitle: string = 'lang_new';

  // 图片组件上传地址
  uploadUrl: string = '/river-fs/file/create';
  // 图片组件预览地址
  pubPreviewUrl: string = '/river-fs/file/view';

  // 表单验证规则
  formRules: Record<string, any> = {
    name: [{ required: true, message: this.$t('lang_fill_in_application_type_name'), tirgger: 'blur' }],
    code: [{ required: true, message: this.$t('lang_fill_in_application_type_code'), tirgger: 'blur' }],
    sort: [{ required: true, type: 'number', message: this.$t('lang_fill_in_the_sort'), tirgger: 'blur' }],
    pid: [{ required: true, message: '请选择父级应用类型', tirgger: 'blur' }],
    type: [{ required: true, message: this.$t('lang_select_application_type_classification'), tirgger: 'blur' }]
  };
  // 应用类型列表数据
  appTypeTypeListData: any[] = [];
  // 选择的部门、用户列表
  private selectedScopeList: RoleSelectDeptUserModel[] = [];

  // App类型树列表
  treeData: any[] = [];

  created() {
    // 获取树
    this.getCatalogTree();
  }

  /**
   * 接收组件传过来的值
   * @param val
   */
  updateSelectDeptUser(val) {
    this.selectedScopeList = val;
  }

  /**
   * 获取App类型树
   */
  getCatalogTree() {
    let params = {};
    ArchiveApi.getCatalogTree(params).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        // 个人的
        let ownerCatalog = res.data.filter(item => item.owner != -1);
        // 默认的
        let defaultCatalog = res.data.filter(item => item.owner == -1);
        this.treeData = [{ id: '-1', name: 'lang_top_node', children: this.currentTab == 'default' ? defaultCatalog : ownerCatalog }];
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 下拉选转换prop
   * @param node
   */
  treeProps(node) {
    return {
      id: node.id,
      label: node.name,
      children: node.children
    };
  }

  /**
   * 获取表单数据
   */
  getDetail() {
    if (this.catalog) {
      this.formData = { ...this.catalog };
      if (this.currentTab == 'default' && this.formData.id) {
        this.getPowerData(this.formData.id);
      }
      this.dialogTitle = 'lang_edit';
    } else {
      this.formData = {};
      this.dialogTitle = 'lang_new';
    }
  }

  /**
   * 获取权限数据
   */
  getPowerData(id) {
    ArchiveApi.getDetailsById({ id: id })
      .then((res: Record<any, any>) => {
        console.log(res);
        if (res.code == '1') {
          if (res.data.opScope) {
            let groupList = res.data.opScope.groups;
            let userList = res.data.opScope.users;
            if (groupList) {
              groupList.forEach(item => {
                let row: RoleSelectDeptUserModel = {
                  id: item.id,
                  type: 'org',
                  name: item.name
                };
                this.selectedScopeList.push(row);
              });
            }

            if (userList) {
              userList.forEach(item => {
                let row: RoleSelectDeptUserModel = {
                  id: item.id,
                  type: 'user',
                  name: item.name
                };
                this.selectedScopeList.push(row);
              });
            }
          }
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {});
  }

  //保存
  async saveFormData() {
    // 验证表单
    await this.formRef.validate();

    // 给默认目录设置权限
    if (this.currentTab == 'default') {
      let users: any[] = [];
      let groups: any[] = [];
      if (this.selectedScopeList && this.selectedScopeList.length > 0) {
        // 构建请求数据组合身份范围参数
        this.selectedScopeList.forEach(item => {
          let obj = {
            id: item.id,
            name: item.name,
            scope: '4'
          };
          if (item.type === 'user') {
            users.push(obj);
          } else if (item.type === 'org') {
            groups.push(obj);
          }
        });
      }
      this.formData.opScope = { users, groups };
      this.formData.owner = '-1';
    }

    // 请求参数
    let param = Object.assign({}, this.formData);
    ArchiveApi.save(param)
      .then((res: Record<any, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          // 关闭弹窗
          this.handleCloseDialog(true);
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {});
  }

  // 关闭弹窗
  handleCloseDialog(reload) {
    // 清除表单验证
    this.formRef.clearValidate();
    this.updateVisible(false);
    this.$emit('close', reload);
  }

  get dialogVisible() {
    return this.visible;
  }

  set dialogVisible(newValue) {
    this.updateVisible(false);
  }

  /**
   * update visible
   * @param value
   */
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  /**
   * 监听弹窗显示隐藏
   * @param val
   */
  @Watch('visible')
  visibleWatcher(val) {
    if (val) {
      this.getDetail();
      this.getCatalogTree();
    } else {
      this.selectedScopeList = [];
      // 清理表单验证
      this.formRef.resetFields();
    }
  }
}
