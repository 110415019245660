














































































import { Component, Prop, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import ArchiveCatalogForm from './archive-catalog-form.vue';
import ArchiveContentForm from './archive-content-form.vue';
import ArchiveApi from '@/api/archive/ArchiveApi';
import DateUtil from 'global-ui/packages/utils/DateUtil';

@Component({
  name: 'ArchiveContent',
  components: { ArchiveCatalogForm, ArchiveContentForm }
})
export default class ArchiveContent extends Vue {
  // 应用id
  @Prop() catalog!: any;

  DateUtil: any = DateUtil;

  catalogData: any = {};
  // 表单列表
  formList: any = [];
  // 文件夹列表
  folderList: any[] = [];
  // 文件列表
  fileList: any[] = [];

  searchParams: any = {};

  liColorS: any = [
    'linear-gradient(90deg, #BA7AE8, #8364E2)',
    'linear-gradient(90deg, #699CF1, #4059E3)',
    'linear-gradient(90deg, #25B8EF, #2B70D1)',
    'linear-gradient(90deg, #3E4E89, #353065)'
  ];

  //选中的树节点
  selectNode: any = {};
  // 是否显示目录类型弹窗
  folderDialogVisible: boolean = false;

  // 选择的归档文件
  selectFile: any = {};
  // 是否显示内容编辑框
  fileDialogVisible: boolean = false;

  @ProvideReactive() formId: any = null;
  @ProvideReactive() formDataId: any = null;

  created() {
    this.initData();
  }

  initData(catalog = this.catalog) {
    this.catalogData = { ...catalog };
    this.getFolderList(catalog);
    this.getFileList(catalog);
  }

  /**
   * 获取该目录下的文件夹
   */
  getFolderList(catalog = this.catalog) {
    if (catalog && catalog.id) {
      let params: any = { pid: catalog.id };
      if (this.searchParams.objectName) {
        params.name = this.searchParams.objectName;
      }
      ArchiveApi.getCatalogList(params).then((res: any) => {
        if (res.code == '1') {
          this.folderList = res.data;
        } else {
          this.folderList = [];
          this.$message.error(res.message);
        }
      });
    }
  }

  /**
   * 获取该目录下的文件
   */
  getFileList(catalog = this.catalog) {
    if (catalog && catalog.id) {
      let params: any = { catalogueId: catalog.id };
      if (this.searchParams.objectName) {
        params.objectName = this.searchParams.objectName;
      }
      ArchiveApi.getFiles(params).then((res: any) => {
        if (res.code == '1') {
          this.fileList = res.data;
        } else {
          this.fileList = [];
          this.$message.error(res.message);
        }
      });
    }
  }

  handleSwitch(item) {
    this.initData(item);
    this.$emit('change', item);
  }

  /**
   * 查看归档详情
   */
  editFolder(folder) {
    this.selectNode = { ...folder };
    this.folderDialogVisible = true;
  }

  /**
   * 查看归档详情
   */
  removeFolder(folder) {
    this.$confirm(this.$t('lang_whether_or_not_to_delete') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    })
      .then(() => {
        let params = [folder.id];
        ArchiveApi.removeCatalogs(params)
          .then((res: Record<any, any>) => {
            if (res.code == '1') {
              this.getFolderList();
              this.$emit('reload');
            } else {
              this.$message.error(res.message);
            }
          })
          .finally(() => {});
      })
      .catch(() => {});
  }

  /**
   * 关闭弹窗
   */
  closeFolderDialog(reload) {
    this.folderDialogVisible = false;
    if (reload) {
      this.getFolderList();
      this.$emit('reload');
    }
  }

  editorFile(data) {
    this.selectFile = { ...data };
    this.fileDialogVisible = true;
  }

  /**
   * 查看归档详情
   */
  removeFile(file) {
    this.$confirm(this.$t('lang_whether_or_not_to_delete') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    })
      .then(() => {
        let params = [file.id];
        ArchiveApi.removeFiles(params)
          .then((res: Record<any, any>) => {
            if (res.code == '1') {
              this.getFileList();
            } else {
              this.$message.error(res.message);
            }
          })
          .finally(() => {});
      })
      .catch(() => {});
  }

  /**
   * 关闭归档内容弹窗
   */
  closeContentDialog(reload) {
    this.folderDialogVisible = false;
    if (reload) {
      this.getFileList();
    }
  }

  /**
   * 搜索
   */
  handleSearch() {
    this.getFolderList();
    this.getFileList();
  }

  /**
   * 查看归档详情
   */
  viewFile(file) {
    this.$router.push({ path: '/front2/archive', query: { id: file.objectId } });
  }

  @Watch('catalog')
  handlerCreated(val) {
    if (val) {
      this.initData();
    }
  }
}
