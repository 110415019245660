var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "60%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title: _vm.$t(_vm.dialogTitle),
        "custom-class": "cus-dialog",
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          attrs: {
            model: _vm.formData,
            rules: _vm.formRules,
            "label-suffix": ": ",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            id: "name",
                            label: _vm.$t("lang_name"),
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.formData.objectName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "objectName", $$v)
                              },
                              expression: "formData.objectName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            id: "parentId",
                            label: _vm.$t("lang_file_directory"),
                            prop: "pid",
                          },
                        },
                        [
                          _c("treeselect", {
                            staticClass: "cus-treeselect",
                            attrs: {
                              options: _vm.treeData,
                              "append-to-body": "",
                              "z-index": 2999,
                              "default-expand-level": 1,
                              normalizer: _vm.treeProps,
                              multiple: _vm.multiple,
                              flat: _vm.multiple,
                              placeholder: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "option-label",
                                fn: function (ref) {
                                  var node = ref.node
                                  return [
                                    _vm._v(" " + _vm._s(_vm.$t(node.label))),
                                  ]
                                },
                              },
                              {
                                key: "value-label",
                                fn: function (ref) {
                                  var node = ref.node
                                  return [
                                    _vm._v(" " + _vm._s(_vm.$t(node.label))),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.formData.catalogueId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "catalogueId", $$v)
                              },
                              expression: "formData.catalogueId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            id: "sort",
                            label: _vm.$t("lang_describe"),
                            prop: "sort",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              col: "4",
                              type: "textarea",
                              placeholder: "",
                              size: "normal",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formData.objectDescription,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "objectDescription", $$v)
                              },
                              expression: "formData.objectDescription",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveFormData } },
            [_vm._v(_vm._s(_vm.$t("lang_save_")))]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }