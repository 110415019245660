



























































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import ArchiveApi from '@/api/archive/ArchiveApi';

@Component({
  name: 'ArchiveContentForm',
  components: {}
})
export default class ArchiveCatalogForm extends Vue {
  // ref
  @Ref() readonly formRef;

  // 弹窗显隐
  @Prop({ default: false }) visible!: boolean;

  // 目录对象
  @Prop() content: any;

  // 弹窗显隐
  @Prop({ default: false }) multiple!: boolean;

  // 目录对象-表单
  formData: any = {
    objectName: '',
    objectDescription: '',
    catalogueId: null
  };

  dialogTitle: string = 'lang_new';

  // 表单验证规则
  formRules: Record<string, any> = {
    objectName: [{ required: true, message: this.$t('lang_fill_archvie_name'), tirgger: 'blur' }],
    catalogueId: [{ required: true, tirgger: 'blur', validator: this.validCatalog }]
  };
  // 应用类型列表数据
  appTypeTypeListData: any[] = [];

  // App类型树列表
  treeData: any[] = [];

  created() {
    // 获取树
    this.getCatalogTree();
  }

  validCatalog(rule, val, callback) {
    if (this.multiple && val.length) {
      callback();
    } else if (val) {
      callback();
    } else {
      callback(new Error(this.$t('lang_select_catalog') as string));
    }
  }

  /**
   * 获取App类型树
   */
  getCatalogTree() {
    let params = {};
    ArchiveApi.getCatalogTree(params).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.treeData = [{ id: '-1', name: 'lang_top_node', children: res.data }];
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 下拉选转换prop
   * @param node
   */
  treeProps(node) {
    return {
      id: node.id,
      label: node.name,
      children: node.children
    };
  }

  /**
   * 获取表单数据
   */
  getDetail() {
    if (this.content) {
      this.formData = { ...this.content };
      this.dialogTitle = 'lang_edit';
    } else {
      this.formData = {};
      this.dialogTitle = 'lang_new';
    }
  }

  //保存
  async saveFormData() {
    // 验证表单
    await this.formRef.validate();
    delete this.formData.intoUser;
    delete this.formData.intoDate;
    let params: any = [];
    if (this.multiple) {
      let catalogs = this.formData.catalogueId || [];
      catalogs.forEach(id => {
        let tmp: any = { ...this.formData };
        tmp.catalogueId = id;
        params.push(tmp);
      });
    } else {
      params.push(this.formData);
    }

    ArchiveApi.saveBatch(params)
      .then((res: Record<any, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          // 关闭弹窗
          this.handleCloseDialog(true);
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {});
  }

  /**
   * update visible
   * @param value
   */
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 关闭弹窗
  handleCloseDialog(reload) {
    // 清除表单验证
    this.formRef.clearValidate();
    this.updateVisible(false);
    this.$emit('close', reload);
  }

  get dialogVisible() {
    return this.visible;
  }

  set dialogVisible(newValue) {
    this.updateVisible(false);
  }

  /**
   * 监听弹窗显示隐藏
   * @param val
   */
  @Watch('visible')
  visibleWatcher(val) {
    if (val) {
      this.getDetail();
      this.getCatalogTree();
    }
  }
}
