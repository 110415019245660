import HttpUtil from '@/utils/HttpUtil';

export default {
  /**
   * 获取归档目录
   */
  getCatalogTree(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/catalogue/tree';
    return HttpUtil.post(url, params);
  },

  /**
   * 获取归档目录
   */
  getCatalogList(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/catalogue/list';
    return HttpUtil.post(url, params);
  },

  /**
   * 获取归档目录下的文件夹
   */
  getFolders(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/catalogue/folder';
    return HttpUtil.post(url, params);
  },

  /**
   * 保存归档目录
   */
  save(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/catalogue/save';
    return HttpUtil.post(url, params);
  },
  /**
   * 移除归档目录
   */
  removeCatalogs(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/catalogue/delete';
    return HttpUtil.post(url, params);
  },

  /**
   * 获取归档目录下的文件
   */
  getFiles(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/list';
    return HttpUtil.post(url, params);
  },

  /**
   * 批量归档
   */
  saveBatch(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/batchSave';
    return HttpUtil.post(url, params);
  },
  /**
   * 移除归档目录
   */
  removeFiles(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/delete';
    return HttpUtil.post(url, params);
  },
  /**
   * 获取目录详情
   */
   getDetailsById(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/catalogue/getDetailsById';
    return HttpUtil.get(url, params);
  }
};
